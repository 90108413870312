import React from 'react';
import './skeleton.css';
import { FiSettings } from 'react-icons/fi';

const FullPageLoader = () => {
  return (
    <div className="full-page-loader">
      <FiSettings className="spinning-icon" />
    </div>
  );
};

export default FullPageLoader;
