import React from "react";
import Records from "../../components/records/records";

function RecordPage() {
  return (
    <>
      <Records />
    </>
  );
}

export default RecordPage;
