import React, { useState, useEffect } from "react";
import Navbar from "../../Dashboard/navbar/nav";
import Sidebar from "../../Dashboard/sidebar/sidebar";
import Upload from "./upload/upload";
import { useNavigate, useParams } from "react-router-dom";
import Requests from "../../../API/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function Revisiondescription() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [countData, setcountData] = useState({});

  useEffect(() => {
    const DataCount = async () => {
      try {
        const response = await Requests.get("getAllOrderCounts");
        // console.log(response.data)
        setcountData(response.data);
      } catch (err) {
        // console.log(err)
      }
    };

    DataCount();
  }, []);

  const [singleRevision, setsingleRevision] = useState(null);
  // console.log(singleRevision);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  // console.log(id)

  useEffect(() => {
    const SingleorderRevision = async () => {
      try {
        setLoading(true);

        const response = await Requests.get(`singleorder/${id}`);
        // console.log(response.data.singleorderdetails.order)
        setsingleRevision(response.data.singleorderdetails);
        setLoading(false);
      } catch (err) {
        // console.log(err);
        if (err.response.status === 401) {
          toast.error("Not Authorized");
          navigate("/sign-in");
        } else if (err.response.status === 500) {
          toast.error("A problem with our servers, hang on");
        }
      } finally {
        setLoading(false);
      }
    };

    SingleorderRevision();
  }, [id, navigate]);

  const formatDeadline = (deadline) => {
    const now = moment();
    const end = moment(deadline);
    const duration = moment.duration(end.diff(now));
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.minutes());
    return `${hours} hours ${minutes} minutes`;
  };

  const renderFile = (fileUrl, fileName) => {
    return (
      // className="file-item"
      <div key={fileUrl} style={{ marginTop: "20px" }}>
        <a
          href={fileUrl}
          download={fileName}
          target="_blank"
          rel="noopener noreferrer"
        >
          {fileName}
        </a>
      </div>
    );
  };

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} />

      <main className="main-dashboard">
        <div className={`sidebar-render ${isSidebarOpen ? "open" : ""}`}>
          <Sidebar isOpen={isSidebarOpen} sidebarData={countData} />
        </div>

        <div className="dashboard-render">
          <div className="dashboard-render-container available-jobs">
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "21px",
                marginTop: "20px",
              }}
            >
              Revision Gig Details
            </p>

            <div className="gig-details">
              <div className="bids">
                <div className="job-name">
                  <p>{singleRevision?.order?.title || "Title"}</p>
                </div>

                <div className="job-description">
                  <p>{singleRevision?.order?.description || "Description"}</p>
                </div>

                <div className="files">
                  <p>Your Submitted Files</p>

                  {singleRevision?.order.submittedfiles &&
                  singleRevision.order.submittedfiles.length > 0 ? (
                    singleRevision.order.submittedfiles.map((fileUrl, index) =>
                      renderFile(fileUrl, `File ${index + 1}`)
                    )
                  ) : (
                    <p>No attached files</p>
                  )}
                </div>

                <div className="place-bid">
                  <p>Comments on the Revision</p>

                  <p
                    style={{
                      fontSize: "18px",
                      marginTop: "10px",
                      fontWeight: "500",
                      marginLeft: "5px",
                      color: "yellow",
                    }}
                  >
                    {singleRevision?.order?.revisioncomment || "comments"}
                  </p>
                </div>

                <p
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Submit Your Revised Work Here
                </p>

                <Upload orderId={id} />
              </div>

              <div className="details">
                <div className="specifics">
                  <p style={{ color: "white", fontWeight: "bold" }}>
                    Gig Specifications
                  </p>

                  <div className="specifics-container">
                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Gig Name</p>
                      </div>
                      <div className="gig-specs-value overflow-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          {singleRevision?.order?.title || "Title"}
                        </p>
                      </div>
                    </div>

                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Deadline</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "red", fontWeight: "bold" }}>
                          {singleRevision
                            ? formatDeadline(singleRevision.order.deadline)
                            : "No deadline"}
                        </p>
                      </div>
                    </div>

                    {/* <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Payment Date</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "green", fontWeight: "bold" }}>
                          {singleRevision?.order.payment_date || Date.now()}
                        </p>
                      </div>
                    </div> */}
                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>category</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          {singleRevision?.order.category || "Writing"}
                        </p>
                      </div>
                    </div>
                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Subcategory</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          {singleRevision?.order.subcategories || "Writing"}
                        </p>
                      </div>
                    </div>

                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Budget</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          $ {singleRevision?.order.writerbudget || "0"}
                        </p>
                      </div>
                    </div>

                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Paper Format</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          {singleRevision?.order.paperformat || "N/A"}
                        </p>
                      </div>
                    </div>

                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Status</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "red", fontWeight: "bold" }}>
                          {singleRevision?.order.status || "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Revisiondescription;
