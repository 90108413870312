import React, { useState, useEffect } from "react";
import "./nav.css";
import Logo from "../../../Assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { TiThMenuOutline } from "react-icons/ti";
import { IoMdArrowDropdown } from "react-icons/io";
// import { Menubar } from 'primereact/menubar';
import { PiHandDepositFill, PiHandWithdrawFill } from "react-icons/pi";
import { LuHistory } from "react-icons/lu";
import icon from "../../../Assets/personIcon.png";
import { CgProfile } from "react-icons/cg";
import { RiLogoutCircleRFill } from "react-icons/ri";
import useDropdown from "./navbarUtils";
import Sidebar from "../sidebar/sidebar";
import DepositModal from "./modals/deposit/deposit";
import WithdrawModal from "./modals/withdraw/withdraw";
import { useAppDispatch } from "../../../state/hook";
import { setIsLogged } from "../../../state/actions/loggedAction";
import Requests from "../../../API/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
function Navbar({ toggleSidebar }) {
  const [balance, setBalance] = useState(100);
  const [isDepositModalOpen, setDepositModalOpen] = useState(false);
  const [isWithdrawModalOpen, setWithdrawModalOpen] = useState(false);

  const {
    dropdownVisible: walletDropdownVisible,
    setDropdownVisible: setWalletDropdownVisible,
    dropdownRef: walletDropdownRef,
  } = useDropdown();

  const {
    dropdownVisible: profileDropdownVisible,
    setDropdownVisible: setProfileDropdownVisible,
    dropdownRef: profileDropdownRef,
  } = useDropdown();

  const handleWalletClick = () => {
    setWalletDropdownVisible(!walletDropdownVisible);
  };

  const handleProfileClick = () => {
    setProfileDropdownVisible(!profileDropdownVisible);
  };

  const openDepositModal = () => {
    setDepositModalOpen(true);
    setWalletDropdownVisible(false);
  };

  const closeDepositModal = () => {
    setDepositModalOpen(false);
  };

  const openWithdrawModal = () => {
    setWithdrawModalOpen(true);
    setWalletDropdownVisible(false);
  };

  const closeWithdrawModal = () => {
    setWithdrawModalOpen(false);
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(setIsLogged(false));
    localStorage.clear();
    navigate("/sign-in");
  };

  // const userName = sessionStorage.getItem("userName");
  const userName = localStorage.getItem("userName");

  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState({});
  // console.log(profileData)

  useEffect(() => {
    const profile = async () => {
      try {
        setLoading(true);

        const response = await Requests.get("freelancerdetails");
        // console.log(response)
        setProfileData(response.data.profileimg || "");
        setLoading(false);
      } catch (err) {
        // console.log(err)
        if (err.response.status === 401) {
          toast.error("Not Authorized");
          navigate("/sign-in");
        } else if (err.response.status === 500) {
          toast.error("A problem with our servers, hang on");
        }
      } finally {
        setLoading(false);
      }
    };
    profile();
  }, []);

  return (
    <>
      <DepositModal isOpen={isDepositModalOpen} onClose={closeDepositModal} />
      <WithdrawModal
        isOpen={isWithdrawModalOpen}
        onClose={closeWithdrawModal}
      />
      <section className="nav_side">
        <nav className="navbar">
          <div className="left-nav">
            <div className="sidebar-title">
              <div className="sidebar-logo">
                <Link to="/dashboard">
                  <img src={Logo} alt="logo" className="logo-sidebar-img" />
                </Link>
              </div>

              <div className="sidebar-legit-title">
                <Link to="/dashboard" style={{ textDecoration: "none" }}>
                  <h3>FreeLance Experts</h3>
                </Link>
              </div>
            </div>

            <div className="menu-div">
              <TiThMenuOutline className="menu" onClick={toggleSidebar} />
            </div>

            <div
              className="wallet"
              // onClick={handleWalletClick}
              // ref={walletDropdownRef}
            >
              <div className="wallet-div">
                <p className="wallet-p">
                  {/* Balance: ksh. {balance}{" "} */}
                  N/A
                  {/* <IoMdArrowDropdown className="wallet-dropdown" /> */}
                </p>
              </div>

              {walletDropdownVisible && (
                <div className="dropdown-div">
                  <div className="dropdown-div--" onClick={openDepositModal}>
                    <div className="handdeposit-div">
                      <PiHandDepositFill className="deposit-icon" />
                    </div>

                    <div className="deposit-div--">
                      <p>Deposit</p>
                    </div>
                  </div>

                  <div className="dropdown-div--" onClick={openWithdrawModal}>
                    <div className="handdeposit-div">
                      <PiHandWithdrawFill className="deposit-icon withdraw" />
                    </div>

                    <div className="deposit-div--">
                      <p>Withdraw</p>
                    </div>
                  </div>

                  <div className="dropdown-div--">
                    <div className="handdeposit-div">
                      <LuHistory className="deposit-icon records" />
                    </div>

                    <div className="deposit-div--">
                      <Link
                        to="/records"
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        <p>Records</p>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            className="right-nav"
            onClick={handleProfileClick}
            ref={profileDropdownRef}
          >
            <div className="right-nav-main">
              <div className="icon">
                <img
                  src={profileData ? profileData : icon}
                  alt="profile"
                  className="person-icon"
                />
              </div>

              <div>
                <p className="user-name">{userName ? userName : "user"}</p>
              </div>

              <div>
                <IoMdArrowDropdown className="profile-dropdown" />
              </div>
            </div>

            {profileDropdownVisible && (
              <div className="right-nav-dropdown">
                <div className="profile">
                  <div>
                    <Link
                      to="/profile"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <CgProfile className="profile-dropdown-icon" />
                    </Link>
                  </div>

                  <div>
                    <Link
                      to="/profile"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {" "}
                      <p>Profile</p>
                    </Link>
                  </div>
                </div>

                <div className="profile">
                  <div>
                    <RiLogoutCircleRFill className="profile-dropdown-icon" />
                  </div>

                  <div onClick={handleLogout}>
                    <p>Logout</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </nav>
      </section>
    </>
  );
}

export default Navbar;
