import React from "react";
import Bids from "../../../components/Gigs/bids/bids";

function BidsPage() {
  return (
    <>
      <Bids />
    </>
  );
}

export default BidsPage;
