import React from "react";
import BidJobsDescription from "../../../components/Gigs/bids/bidjobdescription";

function BidsJobDescriptionPage() {
  return (
    <>
      <BidJobsDescription />
    </>
  );
}

export default BidsJobDescriptionPage;
