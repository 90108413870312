import React, { useState, useEffect } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import "./profile.css";
import Navbar from "../Dashboard/navbar/nav";
import Sidebar from "../Dashboard/sidebar/sidebar";
import ProfilePic from "../../Assets/personIcon.png";
import Requests from "../../API/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Profile() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [bio, setBio] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [countData, setCountData] = useState({});
  const [imageFile, setImageFile] = useState(null);
  // console.log(imageFile)
  const [tempImageURL, setTempImageURL] = useState("");
  const [subjects, setSubjects] = useState({
    writing: false,
    articleWriting: false,
    marketing: false,
    legalDrafting: false,
    design: false,
    promotion: false,
    tutor: false,
    customerSupport: false,
  });

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleImageUploadClick = () => {
    document.getElementById("image-upload-input").click();
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setTempImageURL(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    const fetchDataCount = async () => {
      try {
        const response = await Requests.get("getAllOrderCounts");
        setCountData(response.data);
      } catch (err) {
        // console.log(err);
      }
    };

    fetchDataCount();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "bio") {
      setBio(value);
    } else if (name === "email") {
      setEmail(value);
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSubjects({
      ...subjects,
      [name]: checked,
    });
  };

  const uploadImageToCloudinary = async () => {
    const formData = new FormData();
    formData.append("file", imageFile);
    formData.append("upload_preset", "upload"); // Replace with your Cloudinary upload preset

    try {
      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/drh36px3j/upload",
        formData
      );
      // console.log(response)
      return response.data.secure_url;
    } catch (err) {
      // console.error("Error uploading image to Cloudinary", err);
      toast.error("Failed to upload image");
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const subjectList = Object.keys(subjects).filter((key) => subjects[key]);

    setLoading(true);

    let imageUploadURL = imageURL;
    if (imageFile) {
      imageUploadURL = await uploadImageToCloudinary();
      if (!imageUploadURL) {
        setLoading(false);
        return;
      }
    }

    const data = {
      writerpaymentemail: email,
      bio,
      subjects: subjectList.join(", "),
      profileimg: imageUploadURL,
    };

    // console.log(data);

    try {
      const response = await Requests.put("updatedetails", data);
      // console.log(response);
      if (response.status === 200) {
        toast.success("Profile updated successfully");
        setImageURL(imageUploadURL); // Update the profile image URL with the uploaded one
        setTempImageURL(""); // Clear the temporary image URL
      } else {
        toast.error("Failed to update profile");
      }
    } catch (err) {
      toast.error("An error occurred while updating the profile");
      // console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const [profileData, setProfileData] = useState({});
  // console.log(profileData)
  const navigate = useNavigate();

  useEffect(() => {
    const profile = async () => {
      try {
        setLoading(true);

        const response = await Requests.get("freelancerdetails");
        // console.log(response)
        setProfileData(response.data.profileimg || "");
        setLoading(false);
      } catch (err) {
        // console.log(err)
        if (err.response.status === 401) {
          toast.error("Not Authorized");
          navigate("/sign-in");
        } else if (err.response.status === 500) {
          toast.error("A problem with our servers, hang on");
        }
      } finally {
        setLoading(false);
      }
    };
    profile();
  }, []);

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} />

      <main className="main-dashboard">
        <div className={`sidebar-render ${isSidebarOpen ? "open" : ""}`}>
          <Sidebar isOpen={isSidebarOpen} sidebarData={countData} />
        </div>

        <div className="dashboard-render profile-dashboard-render">
          <div className="dashboard-render-container">
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "21px",
                marginTop: "20px",
              }}
            >
              Profile
            </p>

            <div className="profile-main">
              <div className="profile-container">
                <div className="profile-pic">
                  <img
                    // src={tempImageURL || imageURL || ProfilePic || profileData}
                    src={profileData ? profileData : ProfilePic}
                    alt="profile"
                    className="profile-pic-image"
                  />
                  <p className="choose-image" onClick={handleImageUploadClick}>
                    Choose Image
                  </p>
                  <input
                    id="image-upload-input"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleImageUpload}
                  />
                  {imageFile && (
                    <p className="selected-image-name">{imageFile.name}</p>
                  )}
                  <p className="rating-profile">
                    Rating: <span className="rating-number">4.67</span>
                  </p>
                </div>

                <form className="bio" onSubmit={handleSubmit}>
                  <div className="actual-bio">
                    <p
                      style={{
                        marginBottom: "10px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Help us match you to more Clients
                    </p>
                    <p
                      style={{
                        marginBottom: "10px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Tell us more about yourself
                    </p>
                    <textarea
                      name="bio"
                      rows="10"
                      cols="50"
                      className="bio-textarea"
                      placeholder="Write your bio here..."
                      value={bio}
                      onChange={handleInputChange}
                    ></textarea>
                  </div>

                  <div className="update-profile">
                    <p
                      style={{
                        marginBottom: "10px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      For you to receive money, update your email to match that
                      of your paypal account{" "}
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          marginLeft: "2px",
                          fontSize: "18px",
                        }}
                      >
                        *
                      </span>
                    </p>

                    <input
                      name="email"
                      className="input"
                      type="email"
                      placeholder="Your Paypal Email"
                      value={email}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="subject-specific">
                    <p
                      style={{
                        marginBottom: "10px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      What are you Proficient In?
                    </p>
                    <div className="wrap-check-container">
                      {[
                        { id: "writing", label: "Writing" },
                        { id: "articleWriting", label: "Article Writing" },
                        { id: "marketing", label: "Marketing" },
                        { id: "legalDrafting", label: "Legal Drafting" },
                        { id: "design", label: "Graphic Design" },
                        { id: "promotion", label: "Promotions" },
                        { id: "tutor", label: "Tutor Services" },
                        {
                          id: "customerSupport",
                          label: "Customer Support Services",
                        },
                      ].map((subject) => (
                        <div className="wrap-check" key={subject.id}>
                          <input
                            type="checkbox"
                            id={subject.id}
                            name={subject.id}
                            checked={subjects[subject.id]}
                            onChange={handleCheckboxChange}
                          />
                          <label htmlFor={subject.id} className="check-box">
                            {subject.label}
                          </label>
                        </div>
                      ))}
                    </div>
                    <div className="save-profile-button">
                      <button type="submit" className="btn-52">
                        <div className="original">
                          {loading ? (
                            <AiOutlineLoading3Quarters className="loading-icon" />
                          ) : (
                            "Save Profile"
                          )}
                        </div>

                        <div className="letters">
                          <span>S</span>
                          <span>A</span>
                          <span>V</span>
                          <span>E</span>
                          <span>-</span>
                          <span>P</span>
                          <span>R</span>
                          <span>O</span>
                          <span>F</span>
                          <span>I</span>
                          <span>L</span>
                          <span>E</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Profile;
