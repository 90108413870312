import React, { useState, useEffect } from "react";
import Navbar from "../../Dashboard/navbar/nav";
import Sidebar from "../../Dashboard/sidebar/sidebar";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import Requests from "../../../API/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function Revision() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [loading, setLoading] = useState(false);
  const [countData, setcountData] = useState({});
  const [revisionJobs, setRevisionJobs] = useState([]);
  // console.log(revisionJobs)
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await Requests.get("revision");
        // console.log(response.data.orders)
        setRevisionJobs(response.data.orders);
        setLoading(false);
      } catch (err) {
        // console.log(err);
        if (err.response.status === 401) {
          toast.error("Not Authorized");
          navigate("/sign-in");
        } else if (err.response.status === 500) {
          toast.error("A problem with our servers, hang on");
        }
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatDeadline = (deadline) => {
    const now = moment();
    const end = moment(deadline);
    const duration = moment.duration(end.diff(now));
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.minutes());
    return `${hours} hours ${minutes} minutes`;
  };

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = revisionJobs.slice(indexOfFirstJob, indexOfLastJob);

  const nextPage = () => {
    if (indexOfLastJob < revisionJobs.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    const DataCount = async () => {
      try {
        const response = await Requests.get("getAllOrderCounts");
        // console.log(response.data)
        setcountData(response.data);
      } catch (err) {
        // console.log(err)
      }
    };

    DataCount();
  }, []);

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} />

      <main className="main-dashboard">
        <div className={`sidebar-render ${isSidebarOpen ? "open" : ""}`}>
          <Sidebar isOpen={isSidebarOpen} sidebarData={countData} />
        </div>

        <div className="dashboard-render">
          <div className="dashboard-render-container available-jobs">
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "21px",
                marginTop: "20px",
              }}
            >
              Gigs In Revision
            </p>

            <div class="table-container">
              <table class="responsive-table">
                <thead>
                  <tr>
                    <th>Gig Name</th>
                    <th>Budget</th>
                    <th>Deadline</th>
                    <th>Category</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="4" style={{ textAlign: "center" }}>
                        <AiOutlineLoading3Quarters className="loading-icon" />
                      </td>
                    </tr>
                  ) : currentJobs.length === 0 ? (
                    <tr>
                      <td colSpan="4" style={{ textAlign: "center" }}>
                        <p>There are no Gigs at the Moment</p>
                      </td>
                    </tr>
                  ) : (
                    currentJobs.map((job) => (
                      <Link
                        to={`/revision-description/${job._id}`}
                        className="table-link"
                        key={job._id}
                      >
                        <tr>
                          <td style={{ fontWeight: "600" }}>{job.title}</td>
                          <td style={{ color: "green", fontWeight: "600" }}>
                            ${job.writerbudget}
                          </td>
                          <td style={{ color: "red", fontWeight: "600" }}>
                            {formatDeadline(job.deadline)}
                          </td>
                          <td style={{ fontWeight: "600" }}>{job.category}</td>
                          <td style={{ color: "red", fontWeight: "bold" }}>
                            {job.status}
                          </td>
                        </tr>
                      </Link>
                    ))
                  )}
                </tbody>
              </table>
              <div className="pagination">
                <MdKeyboardDoubleArrowLeft
                  className={`pagination-icon ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                  onClick={prevPage}
                />
                <span className="pagination-number">{currentPage}</span>
                <MdKeyboardDoubleArrowRight
                  className={`pagination-icon ${
                    indexOfLastJob >= revisionJobs.length ? "disabled" : ""
                  }`}
                  onClick={nextPage}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Revision;
