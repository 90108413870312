import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAppSelector } from './state/hook'

const ProtectedRoute = () => {
  const islogged = useAppSelector((state) => state.logged)

  return islogged ? <Outlet /> : <Navigate to="/sign-in" />
}

export default ProtectedRoute
