import React from "react";
import "./sidebar.css";
import { MdSpaceDashboard } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { TiTick } from "react-icons/ti";
import { RiAuctionFill, RiLogoutCircleRFill } from "react-icons/ri";
import { GrInProgress, GrTransaction } from "react-icons/gr";
import { AiFillFlag } from "react-icons/ai";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { BiRevision } from "react-icons/bi";
import { FcCancel, FcRating } from "react-icons/fc";
import { FiMenu } from "react-icons/fi";
import { useAppDispatch } from "../../../state/hook";
import { setIsLogged } from "../../../state/actions/loggedAction";

function Sidebar({ isOpen, sidebarData }) {
  // console.log(sidebarData)

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(setIsLogged(false));
    // sessionStorage.clear();
    localStorage.clear();
    navigate("/sign-in");
  };

  return (
    <>
      <section className={`sidebar-wrapper sidebar ${isOpen ? "visible" : ""}`}>
        <div className="sidebar-section">
          <div className="sidebar-content">
            <div>
              <MdSpaceDashboard className="sidebar-icon" />
            </div>
            <div>
              <Link to="/dashboard" style={{ textDecoration: "none" }}>
                <h3 className="h3-text">Dashboard</h3>
              </Link>
            </div>
          </div>
          <div className="sidebar-content">
            <div>
              <TiTick className="sidebar-icon" />
            </div>
            <div>
              <Link to="/available" style={{ textDecoration: "none" }}>
                <h3 className="h3-text">Available Gigs</h3>
              </Link>
            </div>
            <div className="count">
              <p className="count-progress-h1">
                {" "}
                {sidebarData ? sidebarData.availableOrders : "0"}
              </p>
            </div>
          </div>
          <div className="sidebar-content">
            <div>
              <RiAuctionFill className="sidebar-icon" />
            </div>
            <div>
              <Link to="/bids" style={{ textDecoration: "none" }}>
                <h3 className="h3-text">My Bids</h3>
              </Link>
            </div>
            <div className="count-bids-div">
              <h1 className="count-progress-h1">
                {sidebarData ? sidebarData.biddedOrders : "0"}
              </h1>
            </div>
          </div>{" "}
          <div className="sidebar-content">
            <div>
              <GrInProgress className="sidebar-icon" />
            </div>
            <div>
              <Link to="/progress" style={{ textDecoration: "none" }}>
                <h3 className="h3-text">In Progress</h3>
              </Link>
            </div>
            <div className="count-progress">
              <h1 className="count-progress-h1">
                {" "}
                {sidebarData ? sidebarData.ordersInProgress : "0"}
              </h1>
            </div>
          </div>{" "}
          <div className="sidebar-content">
            <div>
              <AiFillFlag className="sidebar-icon" />
            </div>
            <div>
              <Link to="/review" style={{ textDecoration: "none" }}>
                <h3 className="h3-text">In Review</h3>
              </Link>
            </div>
            <div className="count-reviews">
              <h1 className="count-progress-h1">
                {sidebarData ? sidebarData.ordersinreview : "0"}
              </h1>
            </div>
          </div>{" "}
          <div className="sidebar-content">
            <div>
              <BiRevision className="sidebar-icon" />
            </div>
            <div>
              <Link to="/revision" style={{ textDecoration: "none" }}>
                <h3 className="h3-text">Revision</h3>
              </Link>
            </div>
            <div className="count-revision">
              <h1 className="count-progress-h1">
                {" "}
                {sidebarData ? sidebarData.ordersInRevision : "0"}
              </h1>
            </div>
          </div>{" "}
          <div className="sidebar-content">
            <div>
              <IoCheckmarkDoneSharp className="sidebar-icon" />
            </div>
            <div>
              <Link to="/complete" style={{ textDecoration: "none" }}>
                <h3 className="h3-text">Completed</h3>
              </Link>
            </div>
            <div className="count-completed">
              <h1 className="count-progress-h1">
                {sidebarData ? sidebarData.completedOrders : "0"}
              </h1>
            </div>
          </div>{" "}
          <div className="sidebar-content">
            <div>
              <FcCancel className="sidebar-icon" />
            </div>
            <div>
              <Link to="/disputed" style={{ textDecoration: "none" }}>
                <h3 className="h3-text">Disputed</h3>
              </Link>
            </div>
            <div className="count-disputed">
              <h1 className="count-progress-h1">
                {sidebarData ? sidebarData.ordersInDispute : "0"}
              </h1>
            </div>
          </div>{" "}
          <div className="sidebar-content">
            <div>
              <FcRating className="sidebar-icon" />
            </div>
            <div>
              <h3 className="h3-text">Rating</h3>
            </div>
            <div className="count-rating">
              <h1 className="count-progress-h1">{sidebarData ? sidebarData.userrating : "4.0"}</h1>
            </div>
          </div>{" "}
          <div className="sidebar-content">
            <div>
              <GrTransaction className="sidebar-icon" />
            </div>
            <div>
              <Link to="/records" style={{ textDecoration: "none" }}>
                {" "}
                <h3 className="h3-text">Transaction History</h3>
              </Link>
            </div>
          </div>
          <div className="sidebar-content" onClick={handleLogout}>
            <div>
              <RiLogoutCircleRFill className="sidebar-icon" />
            </div>
            <div>
              <h3 className="h3-text">Logout</h3>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Sidebar;
