import React, { useState } from "react";
import "./register.css";
import Logo from "../../../Assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import Requests from "../../../API/api";

function Register() {
  const [regData, setRegData] = useState({
    fullName: "",
    email: "",
    contact: "",
    password: "",
    confirmPassword: "",
  });

  const [load, setLoad] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const handleChange = (e) => {
    setRegData({
      ...regData,
      [e.target.name]: e.target.value,
    });
  };
  const handlePhoneChange = (value) => {
    if (value.length <= 13) {
      setRegData({ ...regData, contact: value });
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !regData.fullName ||
      !regData.email ||
      !regData.contact ||
      !regData.password ||
      !regData.confirmPassword
    ) {
      return toast.error("All fields are required");
    }
    if (regData.password !== regData.confirmPassword) {
      return toast.error("Passwords do not match");
    }
    if (regData.contact.length < 13) {
      return toast.error("Incorrect Phone Number");
    }

    setLoad(true);

    try {
      // const { confirmPassword, ...registerationData } = regData;
      const postRegData = {
        name: regData.fullName,
        contact: regData.contact,
        email: regData.email,
        password: regData.password,
      };

      await Requests.post("register", postRegData);
      // console.log(response)
      toast.success("Registration Successful, Check Your Email To Verify");
      setTimeout(() => {
        navigate("/sign-in");
      }, 2000);

      setLoad(false);
    } catch (err) {
      // console.log(err)
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (regData.password.length < 5) {
        toast.error(
          "Password is too short. Please enter at least 5 characters."
        );
        setLoad(false);
        return;
      } else if (!emailRegex.test(regData.email)) {
        toast.error("Invalid email address. Please enter a valid email.");
        setLoad(false);
        return;
      } else {
        // setErrorMessage(
        //   "There seems to be an error, refresh the page and try again!"
        // );

        toast.error(err.message);
        setLoad(false);
      }
    } finally {
      setLoad(false);
    }
  };

  return (
    <>
      <section
        style={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f3f3f3",
        }}
        className="register-section"
      >
        <main
          style={{
            width: "60%",
            minHeight: "50vh",
            backgroundColor: "#ffffff",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "8px",
            boxShadow: "0 0 10px rgba(86, 128, 233, 0.1)",
          }}
          className="main-regsiter-section"
        >
          <form
            style={{
              width: "100%",
              height: "100%",
              marginTop: "10px",
              padding: "10px",
            }}
            className="form-register"
            onSubmit={handleSubmit}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                justifyContent: "center",
              }}
              className="div-title"
            >
              <div className="logo-image-div">
                <img
                  src={Logo}
                  alt="logo"
                  style={{
                    height: "40px",
                    width: "auto",
                    backgroundSize: "cover",
                    cursor: "pointer",
                  }}
                  className="logo-title"
                />
              </div>
              <div>
                <h2 style={{ textAlign: "center", cursor: "pointer" }}>
                  FreeLance Experts
                </h2>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <h3
                style={{
                  fontSize: "15px",
                  fontWeight: "bolder",
                  fontFamily: "'Jacquard 12', system-ui",
                }}
                className="motto"
              >
                Empowering Freelancers, Empowering You
              </h3>
            </div>

            <p
              style={{
                textAlign: "center",
                marginTop: "20px",
                fontWeight: "600",
              }}
            >
              Register As a Freelancer
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "40px",
              }}
              className="div-inputs-main-container"
            >
              <div style={{ margin: "0px 3%" }}>
                <p style={{ fontWeight: "600" }}>Name</p>
              </div>

              <div className="wrap-input-9">
                <input
                  className="input"
                  type="text"
                  placeholder="Enter your FullName"
                  name="fullName"
                  value={regData.fullName}
                  onChange={handleChange}
                  required
                />
                <span className="focus-border">
                  <i></i>
                </span>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "40px",
              }}
              className="div-inputs-main-container"
            >
              <div style={{ margin: "0px 3%" }}>
                <p style={{ fontWeight: "600" }}>Email</p>
              </div>

              <div className="wrap-input-9">
                <input
                  className="input"
                  type="email"
                  placeholder="Enter your Email"
                  name="email"
                  value={regData.email}
                  onChange={handleChange}
                  required
                />
                <span className="focus-border">
                  <i></i>
                </span>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "40px",
              }}
              className="div-inputs-main-container"
            >
              <div style={{ margin: "0px 3%" }}>
                <p style={{ fontWeight: "600" }}>Contact</p>
              </div>

              <div className="wrap-input-9">
                <PhoneInput
                  defaultCountry="ke"
                  className="input"
                  placeholder="Enter your Mpesa phone number **"
                  value={regData.contact}
                  onChange={handlePhoneChange}
                  required
                />
                <span className="focus-border">
                  <i></i>
                </span>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "40px",
              }}
              className="div-inputs-main-container"
            >
              <div style={{ margin: "0px 3%" }}>
                <p style={{ fontWeight: "600" }}>Password</p>
              </div>

              <div className="wrap-input-9">
                <input
                  className="input"
                  type={passwordVisible ? "text" : "password"}
                  placeholder="Enter your Password"
                  name="password"
                  value={regData.password}
                  onChange={handleChange}
                  required
                />
                <span className="focus-border">
                  <i></i>
                </span>
                <span
                  className="toggle-password"
                  onClick={togglePasswordVisibility}
                >
                  {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "40px",
              }}
              className="div-inputs-main-container"
            >
              <div style={{ margin: "0px 3%" }}>
                <p style={{ fontWeight: "600" }}>Confirm Password</p>
              </div>

              <div className="wrap-input-9">
                <input
                  className="input"
                  type={confirmPasswordVisible ? "text" : "password"}
                  placeholder="Confirm your Password"
                  name="confirmPassword"
                  value={regData.confirmPassword}
                  onChange={handleChange}
                  required
                />
                <span className="focus-border">
                  <i></i>
                </span>
                <span
                  className="toggle-password"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  {confirmPasswordVisible ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <button className="btn-81" type="submit">
                <span>
                  {load ? (
                    <div className="dot-flashing"></div>
                  ) : (
                    <span>Next</span>
                  )}
                </span>
              </button>

              <div className="link-div-tags">
                <a
                  href="https://thefreelanceexperts.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ fontWeight: "600", textDecoration: "none" }}
                >
                  <p>Click Here if You are an Employer</p>
                </a>

                <p className="p-link">
                  Already Registered?{" "}
                  <Link
                    to="sign-in"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <span>Sign In</span>
                  </Link>
                </p>
              </div>
            </div>
          </form>
        </main>
      </section>
    </>
  );
}

export default Register;
