import React, { useState, useEffect } from "react";
import "./jobdescription.css";
import Navbar from "../../Dashboard/navbar/nav";
import Sidebar from "../../Dashboard/sidebar/sidebar";
import { useNavigate, useParams } from "react-router-dom";
import Requests from "../../../API/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
function Jobdescription() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [countData, setcountData] = useState({});

  useEffect(() => {
    const DataCount = async () => {
      try {
        const response = await Requests.get("getAllOrderCounts");
        // console.log(response.data)
        setcountData(response.data);
      } catch (err) {
        // console.log(err)
      }
    };

    DataCount();
  }, []);

  const [singleOrder, setSingleOrder] = useState(null);
  // console.log(singleOrder);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  // console.log(id)

  useEffect(() => {
    const Singleorder = async () => {
      try {
        setLoading(true);

        const response = await Requests.get(`singleorder/${id}`);
        // console.log(response.data);
        setSingleOrder(response.data);
        setLoading(false);
      } catch (err) {
        // console.log(err);
        if (err.response.status === 401) {
          toast.error("Not Authorized");
          navigate("/sign-in");
        } else if (err.response.status === 500) {
          toast.error("A problem with our servers, hang on");
        }
      } finally {
        setLoading(false);
      }
    };

    Singleorder();
  }, [id, navigate]);

  const renderFile = (fileUrl, fileName) => {
    return (
      // className="file-item"
      <div key={fileUrl} style={{ marginTop: "20px" }}>
        <a
          href={fileUrl}
          download={fileName}
          target="_blank"
          rel="noopener noreferrer"
        >
          {fileName}
        </a>
      </div>
    );
  };

  // const renderFile = (fileUrl) => {
  //   const fileName = fileUrl.split('/').pop(); // Extract file name from URL
  //   return (
  //     <div key={fileUrl} style={{ marginTop: "20px" }}>
  //       <a
  //         href={fileUrl}
  //         download={fileName}
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         {fileName}
  //       </a>
  //     </div>
  //   );
  // };

  const formatDeadline = (deadline) => {
    const now = moment();
    const end = moment(deadline);
    const duration = moment.duration(end.diff(now));
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.minutes());
    return `${hours} hours ${minutes} minutes`;
  };

  // LOGIC FOR PLACING A BID
  const [bid, setBid] = useState();
  const handleBidData = (e) => {
    setBid(e.target.value);
  };

  const handleSubmitBidData = async (e) => {
    e.preventDefault();

    const bidData = {
      bidtext: bid,
      orderid: id,
    };

    try {
      setLoading(true);

      const response = await Requests.post("placebid", bidData);
      // console.log(response)
      toast.success("Bid Placed Successfully");

      setTimeout(() => {
        navigate("/bids");
      }, 1000);

      setLoading(false);
    } catch (err) {
      // console.log(err);
      if (err.response.status === 401) {
        toast.error("Not Authorized");
        navigate("/sign-in");
      } else if (err.response.status === 500) {
        toast.error("A problem with our servers, hang on");
      } else if (err.response.status === 400) {
        toast.error(err.response.data);
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} />

      <main className="main-dashboard">
        <div className={`sidebar-render ${isSidebarOpen ? "open" : ""}`}>
          <Sidebar isOpen={isSidebarOpen} sidebarData={countData} />
        </div>

        <div className="dashboard-render">
          <div className="dashboard-render-container available-jobs">
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "21px",
                marginTop: "20px",
              }}
            >
              Gig Details
            </p>

            <div className="gig-details">
              <div className="bids">
                <div className="job-name">
                  <p>
                    {singleOrder?.singleorderdetails?.order.title || "Title"}
                  </p>
                </div>

                <div className="job-description">
                  <p>
                    {singleOrder?.singleorderdetails?.order.description ||
                      "description"}
                  </p>
                </div>

                <div className="files">
                  <p>Attached Files</p>
                  {singleOrder?.singleorderdetails?.order.files &&
                  singleOrder.singleorderdetails.order.files.length > 0 ? (
                    singleOrder.singleorderdetails.order.files.map(
                      (fileUrl, index) =>
                        renderFile(fileUrl, `File ${index + 1}`)
                    )
                  ) : (
                    <p>No attached files</p>
                  )}
                </div>

                <div className="place-bid">
                  {/* <p style={{ fontWeight: "600" }}>
                    Bids: {singleOrder?.singleorder?.bids.length || "0"}
                  </p> */}

                  <p style={{ fontWeight: "600" }}>
                    Bids:{singleOrder?.totalbids || "0"}
                  </p>

                  <p
                    style={{
                      fontSize: "18px",
                      marginTop: "10px",
                      fontWeight: "500",
                      marginLeft: "5px",
                    }}
                  >
                    Place Your bid
                  </p>

                  <form onSubmit={handleSubmitBidData}>
                    <div className="wrap-input-8">
                      <textarea
                        className="input"
                        rows="8"
                        cols="33"
                        placeholder="Place your bid here"
                        value={bid}
                        onChange={handleBidData}
                      ></textarea>
                      <span className="focus-border">
                        <i></i>
                      </span>
                    </div>

                    <button class="btn-54">
                      <span class="shadow"></span>
                      <span class="depth"></span>
                      <span class="content">
                        {loading ? (
                          <AiOutlineLoading3Quarters className="loading-icon" />
                        ) : (
                          <span>Place Bid?</span>
                        )}
                      </span>
                    </button>
                  </form>
                </div>
              </div>

              <div className="details">
                <div className="specifics">
                  <p style={{ color: "white", fontWeight: "bold" }}>
                    Gig Specifications
                  </p>

                  <div className="specifics-container">
                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Gig Name</p>
                      </div>
                      <div className="gig-specs-value overflow-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          {singleOrder?.singleorderdetails?.order.title ||
                            "Title"}
                        </p>
                      </div>
                    </div>

                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Deadline</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "red", fontWeight: "bold" }}>
                          {singleOrder
                            ? formatDeadline(
                                singleOrder.singleorderdetails.order.deadline
                              )
                            : "No deadline"}
                        </p>
                      </div>
                    </div>

                    {/*<div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Payment Date</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "green", fontWeight: "bold" }}>
                          {singleOrder?.singleorderdetails?.order
                            .payment_date || Date.now()}
                        </p>
                      </div>
                    </div>*/}

                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Category</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          {singleOrder?.singleorderdetails?.order.category ||
                            "Writing"}
                        </p>
                      </div>
                    </div>

                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>SubCategory</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          {singleOrder?.singleorderdetails?.order
                            .subcategories || "Writing"}
                        </p>
                      </div>
                    </div>

                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Budget</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          ${" "}
                          {singleOrder?.singleorderdetails?.order
                            .writerbudget || "0"}
                        </p>
                      </div>
                    </div>
                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Paper Format</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          {singleOrder?.singleorderdetails?.order.paperformat ||
                            "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Status</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "green", fontWeight: "bold" }}>
                          {singleOrder?.singleorderdetails?.order.status ||
                            "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Jobdescription;
