import React from "react";
import "./deposit.css";

function DepositModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle deposit logic here
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        <p className="feature">
          Feature Not Available yet. Do not worry though. Your money will be
          sent to you by the admin, all he needs is your Mpesa Phone number or
          Paypal Email. You can update your email in the profile page.
        </p>

        <h2>Top Up Your Wallet</h2>
        <p className="modal-disabled-text">
          An STK push will be sent to your mpesa, then enter your pin.
        </p>
        <form onSubmit={handleSubmit}>
          <label>
            Phone Number:
            <input type="text" defaultValue="0776648716" />
          </label>
          <label>
            Amount:
            <input type="number" />
          </label>
          <button type="submit">Pay now</button>
          <button type="button" onClick={onClose}>
            Close
          </button>
        </form>
        <div className="modal-alternatives">
          <p
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              fontWeight: "600",
            }}
          >
            Alternatively
          </p>
          <ol>
            <li>Go to Mpesa . Lipa na Mpesa . Pay Bill</li>
            <li>
              Business no: <strong>4077213</strong>
            </li>
            <li>
              Account no: <strong>ww#19256</strong>
            </li>
            <li>
              Amount: <strong>100</strong>
            </li>
            <li>Enter your M-pesa PIN</li>
          </ol>
        </div>
      </div>
    </div>
  );
}

export default DepositModal;
