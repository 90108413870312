import React, { useState } from "react";
import Logo from "../../../Assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import Requests from "../../../API/api";
import { useAppDispatch } from "../../../state/hook";
import { setIsLogged } from "../../../state/actions/loggedAction";

function Login() {
  const [logData, setLogData] = useState({
    email: "",
    password: "",
  });

  const [load, setLoad] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const passwordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    setLogData({
      ...logData,
      [e.target.name]: e.target.value,
    });
  };

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!logData.email || !logData.password) {
      return toast.error("All fields are required");
    }
    setLoad(true);
    try {
      const postLogData = {
        email: logData.email,
        password: logData.password,
      };

      const response = await Requests.post("login", postLogData);
      if (response.data && response.data._id && response.data.name) {
        // localStorage.setItem('userId', response.data._id)
        localStorage.setItem("userName", response.data.name);
      } else {
        // Data not available
        // console.error("User data is missing in response");
      }
      dispatch(setIsLogged(true));
      toast.success("Login Successful");
      setTimeout(() => {
        navigate("/dashboard");
      }, 2000);

      setLoad(false);
    } catch (err) {
      // console.log(err)
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (logData.password.length < 5) {
        toast.error(
          "Password is too short. Please enter at least 5 characters."
        );
        setLoad(false);
        return;
      } else if (!emailRegex.test(logData.email)) {
        toast.error("Invalid email address. Please enter a valid email.");
        setLoad(false);
        return;
      } else {
        setErrorMessage("Incorrect Email or Password");
        toast.error(errorMessage);
        setLoad(false);
      }
    } finally {
      setLoad(false);
    }
  };

  return (
    <>
      <section
        style={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f3f3f3",
        }}
        className="register-section"
      >
        <main
          style={{
            width: "60%",
            minHeight: "60vh",
            backgroundColor: "#ffffff",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "8px",
            boxShadow: "0 0 10px rgba(86, 128, 233, 0.1)",
          }}
          className="main-register-section main-login-section"
        >
          <form
            style={{
              width: "100%",
              height: "100%",
              marginTop: "10px",
              padding: "10px",
            }}
            className="form-register"
            onSubmit={handleSubmit}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                justifyContent: "center",
              }}
              className="div-title"
            >
              <div className="logo-image-div">
                <img
                  src={Logo}
                  alt="logo"
                  style={{
                    height: "40px",
                    width: "auto",
                    backgroundSize: "cover",
                    cursor: "pointer",
                  }}
                  className="logo-title"
                />
              </div>
              <div>
                <h2 style={{ textAlign: "center", cursor: "pointer" }}>
                  FreeLance Experts
                </h2>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <h3
                style={{
                  fontSize: "15px",
                  fontWeight: "bolder",
                  fontFamily: "'Jacquard 12', system-ui",
                }}
                className="motto"
              >
                Empowering Freelancers, Empowering You
              </h3>
            </div>

            <p
              style={{
                textAlign: "center",
                marginTop: "20px",
                fontWeight: "600",
              }}
            >
              Login As a Freelancer
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "40px",
              }}
              className="div-inputs-main-container"
            >
              <div style={{ margin: "0px 3%" }}>
                <p style={{ fontWeight: "600" }}>Email</p>
              </div>

              <div class="wrap-input-9">
                <input
                  class="input"
                  type="email"
                  placeholder=" Enter your Email"
                  name="email"
                  value={logData.email}
                  onChange={handleChange}
                  required
                />
                <span class="focus-border">
                  <i></i>
                </span>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "40px",
              }}
              className="div-inputs-main-container"
            >
              <div style={{ margin: "0px 3%" }}>
                <p style={{ fontWeight: "600" }}>Password</p>
              </div>

              <div className="wrap-input-9">
                <input
                  className="input"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your Password"
                  name="password"
                  value={logData.password}
                  onChange={handleChange}
                  required
                />
                <span className="focus-border">
                  <i></i>
                </span>
                <span className="toggle-password" onClick={passwordVisibility}>
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <button className="btn-81">
                <span>
                  {load ? (
                    <div className="dot-flashing"></div>
                  ) : (
                    <span>Login</span>
                  )}
                </span>
              </button>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <p
                    style={{
                      textAlign: "left",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                    className="p-link"
                  >
                    <Link
                      to="/forgot-password"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <span>Oops, Forgot Password?</span>
                    </Link>
                  </p>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <a
                    href="https://thefreelanceexperts.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ fontWeight: "600", textDecoration: "none" }}
                  >
                    <p>Click Here if You are an Employer</p>
                  </a>
                </div>

                <div>
                  <p
                    style={{
                      textAlign: "right",
                      marginTop: "10px",
                      marginRight: "10px",
                    }}
                    className="p-link"
                  >
                    Not Yet Registered?{" "}
                    <Link
                      to="/"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <span>Register</span>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </form>
        </main>
      </section>
    </>
  );
}

export default Login;
