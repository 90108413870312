import axios from "axios";

// const Requests = axios.create({
//   baseURL: "https://freelancer-server-flk4.onrender.com/api/freelancers/",
//   withCredentials: true,
// });

const Requests = axios.create({
  baseURL: "https://api.thefreelanceexperts.com/api/freelancers/",
  withCredentials: true,
});

export default Requests;
