import React from "react";

function DepositModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle deposit logic here
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        <p className="feature">
          Feature Not Available yet. Do not worry though. Your money will be
          sent to you by the admin, all he needs is your Mpesa Phone number or
          Paypal Email. You can update your email in the profile page.
        </p>

        <h2>Withdraw from your Wallet</h2>
        <p className="modal-disabled-text">
          Enter Amount you want to withdraw.
        </p>
        <form onSubmit={handleSubmit}>
          <label>
            Phone Number:
            <input type="text" defaultValue="0776648716" />
          </label>
          <label>
            Amount:
            <input type="number" />
          </label>
          <button type="submit">Withdraw</button>
          <button type="button" onClick={onClose}>
            Close
          </button>
        </form>
      </div>
    </div>
  );
}

export default DepositModal;
