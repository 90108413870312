import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RegisterPages from "./Pages/Authentication/Register/register";
import LoginPages from "./Pages/Authentication/Login/login";
import DashboardPages from "./Pages/Dashboard/dashboard";
import AvailablePages from "./Pages/Gigs/available/available";
import JobdescriptionPages from "./Pages/Gigs/available/jobdescription";
import BidsPages from "./Pages/Gigs/bids/bids";
import BidsJobDescriptionPage from "./Pages/Gigs/bids/bidjobdescription";
import ProgressPage from "./Pages/Gigs/progress/progress";
import ProgressDescriptionPage from "./Pages/Gigs/progress/progressdescription";
import ReviewPages from "./Pages/Gigs/review/review";
import ReviewdescriptionPages from "./Pages/Gigs/review/reviewdescription";
import RevisionPages from "./Pages/Gigs/revision/revision";
import RevisionDescriptionPages from "./Pages/Gigs/revision/revisiondescription";
import CompletedGigsPage from "./Pages/Gigs/completed/completed";
import DisputedGigsPage from "./Pages/Gigs/disputed/disputed";
import DisputeddescriptionPage from "./Pages/Gigs/disputed/disputeddescription";
import RecordsPage from "./Pages/records/records";
import ProfilePages from "./Pages/profile/profile";
import ProtectedRoute from "./protectedroute";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RegisterPages />} />
        <Route path="/sign-in" element={<LoginPages />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<DashboardPages />} />
          <Route path="/available" element={<AvailablePages />} />
          <Route
            path="/job-description/:id"
            element={<JobdescriptionPages />}
          />
          <Route path="/bids" element={<BidsPages />} />
          <Route
            path="/bid-job-description/:id"
            element={<BidsJobDescriptionPage />}
          />
          <Route path="/progress" element={<ProgressPage />} />
          <Route
            path="/progress-description/:id"
            element={<ProgressDescriptionPage />}
          />
          <Route path="/review" element={<ReviewPages />} />
          <Route
            path="/review-description/:id"
            element={<ReviewdescriptionPages />}
          />
          <Route path="/revision" element={<RevisionPages />} />
          <Route
            path="/revision-description/:id"
            element={<RevisionDescriptionPages />}
          />
          <Route path="/complete" element={<CompletedGigsPage />} />
          <Route path="/disputed" element={<DisputedGigsPage />} />
          <Route
            path="/disputed-description/:id"
            element={<DisputeddescriptionPage />}
          />
          <Route path="/records" element={<RecordsPage />} />
          <Route path="/profile" element={<ProfilePages />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
