import React, { useState, useEffect } from "react";
import "./records.css";
import Navbar from "../Dashboard/navbar/nav";
import Sidebar from "../Dashboard/sidebar/sidebar";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { Link } from "react-router-dom";
import { FaPrint } from "react-icons/fa6";
import Requests from "../../API/api";

function Available() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [countData, setcountData] = useState({});

  useEffect(() => {
    const DataCount = async () => {
      try {
        const response = await Requests.get("getAllOrderCounts");
        // console.log(response.data)
        setcountData(response.data);
      } catch (err) {
        // console.log(err)
      }
    };

    DataCount();
  }, []);

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} />

      <main className="main-dashboard">
        <div className={`sidebar-render ${isSidebarOpen ? "open" : ""}`}>
          <Sidebar isOpen={isSidebarOpen} sidebarData={countData} />
        </div>

        <div className="dashboard-render">
          <div className="dashboard-render-container">
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "21px",
                marginTop: "20px",
              }}
            >
              Transaction History
            </p>

            <div className="table-container">
              <FaPrint className="print" title="print transaction history" />

              <table className="responsive-table">
                <thead>
                  <tr>
                    <th>Date and Time</th>
                    <th>Transaction Reference</th>
                    <th>Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      colSpan={4}
                      style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      Feature not available yet
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="pagination">
                <MdKeyboardDoubleArrowLeft className="pagination-icon" />
                <span className="pagination-number">1</span>
                <MdKeyboardDoubleArrowRight className="pagination-icon" />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Available;
