import React from "react";
import Dashboard from "../../components/Dashboard/dashboard/dashboard";
function DashboardPages() {
  return (
    <>
      <Dashboard />
    </>
  );
}

export default DashboardPages;
