import React, { useState, useEffect } from "react";
import "./dashboard.css";
import Navbar from "../navbar/nav";
import Sidebar from "../sidebar/sidebar";
import { TiTick } from "react-icons/ti";
import { RiAuctionFill } from "react-icons/ri";
import { GrInProgress } from "react-icons/gr";
import { AiFillFlag } from "react-icons/ai";
import { BiRevision } from "react-icons/bi";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { FcCancel, FcRating } from "react-icons/fc";
import { FaSackDollar } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import Requests from "../../../API/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from "../../../Loader/skeleton";

function Dashboard() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [countData, setCountData] = useState(null);
  // console.log(countData)
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState("");
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const responseCount = await Requests.get("getAllOrderCounts");
        // console.log(responseCount);
        setCountData(responseCount.data);

        const responseProfile = await Requests.get("freelancerdetails");
        setProfileData(responseProfile.data.subjects);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          toast.error("Not Authorized");
          navigate("/sign-in");
        } else if (err.response && err.response.status === 500) {
          toast.error("A problem with our servers, hang on");
        }
        // console.log(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  const subjectsArray = profileData ? profileData.split(", ") : [];

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} />
      {loading && <Skeleton />}

      <main className="main-dashboard">
        <div className={`sidebar-render ${isSidebarOpen ? "open" : ""}`}>
          <Sidebar isOpen={isSidebarOpen} sidebarData={countData} />
        </div>
        <div className="dashboard-render">
          <div className="dashboard-render-container">
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "21px",
                marginTop: "20px",
              }}
            >
              Dashboard
            </p>

            <div className="main-flex-container">
              <div className="flex-container">
                <Link to="/available" style={{ textDecoration: "none" }}>
                  <div className="flex-item">
                    <div className="flex-content">
                      <div className="flex-description">
                        <div className="flex-icon">
                          <TiTick className="dashboard-icon" />
                        </div>
                        <div className="flex-title">
                          <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                            Available Gigs
                          </p>
                        </div>
                      </div>
                      <div className="flex-count">
                        <p className="flex-count-p">
                          {countData ? countData.availableOrders : "0"}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/bids" style={{ textDecoration: "none" }}>
                  <div className="flex-item">
                    <div className="flex-content">
                      <div className="flex-description">
                        <div className="flex-icon">
                          <RiAuctionFill className="dashboard-icon" />
                        </div>
                        <div className="flex-title">
                          <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                            My Bids
                          </p>
                        </div>
                      </div>
                      <div className="flex-count">
                        <p className="flex-count-p">
                          {countData ? countData.biddedOrders : "0"}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/progress" style={{ textDecoration: "none" }}>
                  <div className="flex-item">
                    <div className="flex-content">
                      <div className="flex-description">
                        <div className="flex-icon">
                          <GrInProgress className="dashboard-icon" />
                        </div>
                        <div className="flex-title">
                          <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                            In Progress
                          </p>
                        </div>
                      </div>
                      <div className="flex-count">
                        <p className="flex-count-p">
                          {countData ? countData.ordersInProgress : "0"}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/review" style={{ textDecoration: "none" }}>
                  <div className="flex-item">
                    <div className="flex-content">
                      <div className="flex-description">
                        <div className="flex-icon">
                          <AiFillFlag className="dashboard-icon" />
                        </div>
                        <div className="flex-title">
                          <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                            In Review
                          </p>
                        </div>
                      </div>
                      <div className="flex-count">
                        <p className="flex-count-p">
                          {countData ? countData.ordersinreview : "0"}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/revision" style={{ textDecoration: "none" }}>
                  <div className="flex-item">
                    <div className="flex-content">
                      <div className="flex-description">
                        <div className="flex-icon">
                          <BiRevision className="dashboard-icon" />
                        </div>
                        <div className="flex-title">
                          <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                            Revision
                          </p>
                        </div>
                      </div>
                      <div className="flex-count">
                        <p className="flex-count-p">
                          {countData ? countData.ordersInRevision : "0"}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/complete" style={{ textDecoration: "none" }}>
                  <div className="flex-item">
                    <div className="flex-content">
                      <div className="flex-description">
                        <div className="flex-icon">
                          <IoCheckmarkDoneSharp className="dashboard-icon" />
                        </div>
                        <div className="flex-title">
                          <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                            Completed
                          </p>
                        </div>
                      </div>
                      <div className="flex-count">
                        <p className="flex-count-p">
                          {countData ? countData.completedOrders : "0"}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/disputed" style={{ textDecoration: "none" }}>
                  <div className="flex-item">
                    <div className="flex-content">
                      <div className="flex-description">
                        <div className="flex-icon">
                          <FcCancel className="dashboard-icon" />
                        </div>
                        <div className="flex-title">
                          <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                            Disputed
                          </p>
                        </div>
                      </div>
                      <div className="flex-count">
                        <p className="flex-count-p">
                          {countData ? countData.ordersInDispute : "0"}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>

                <div className="flex-item">
                  <div className="flex-content">
                    <div className="flex-description">
                      <div className="flex-icon">
                        <FcRating className="dashboard-icon" />
                      </div>
                      <div className="flex-title">
                        <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                          Rating
                        </p>
                      </div>
                    </div>
                    <div className="flex-count">
                      <p className="flex-count-p">
                        {countData ? countData.userrating : "4.0"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex-item">
                  <div className="flex-content">
                    <div className="flex-description">
                      <div className="flex-icon">
                        <FaSackDollar className="dashboard-icon" />
                      </div>
                      <div className="flex-title">
                        <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                          Total paid
                        </p>
                      </div>
                    </div>
                    <div className="flex-count">
                      <p className="flex-count-p">N/A</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="subjects">
              <p
                style={{
                  marginTop: "20px",
                  marginLeft: "20px",
                  fontWeight: "bolder",
                  fontSize: "20px",
                }}
              >
                Your Speciality
              </p>

              <div className="subjects-flex-container">
                <ul className="subjects-list">
                  {subjectsArray.length > 0 ? (
                    subjectsArray.map((subject, index) => (
                      <li key={index}>{subject}</li>
                    ))
                  ) : (
                    <li>N/A</li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Dashboard;
