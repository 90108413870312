import React from 'react'
import Login from '../../../components/Authentication/Login/login'
import Meta from '../../../components/Meta'

function LoginPages() {
  const data = {
    title: 'Login',
    subject: 'Login',
    keywords:
      'blogs, freelancing, writing, legal drafts, AI Training, Promotions',
    summary:
      'Freelance Experts offers remote employment opportunities. Clients post jobs, freelancers bid, work, and receive payments. Efficient, global freelance marketplace.',
    description:
      'Freelance Experts connects global clients with skilled freelancers. Clients post jobs, freelancers bid, complete tasks, and get paid. Empower your projects with remote talent',
    og_image: window.location.href + '/logo.png',
  }
  return (
    <>
      <Meta data={data} />
      <Login />
    </>
  )
}

export default LoginPages
