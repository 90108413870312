import React, { useState, useEffect } from "react";
import Navbar from "../../Dashboard/navbar/nav";
import Sidebar from "../../Dashboard/sidebar/sidebar";
import "./bidjobdescription.css";
import { useNavigate, useParams } from "react-router-dom";
import Requests from "../../../API/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from "axios";
function Bidjobdescription() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [countData, setcountData] = useState({});

  useEffect(() => {
    const DataCount = async () => {
      try {
        const response = await Requests.get("getAllOrderCounts");
        // console.log(response.data)
        setcountData(response.data);
      } catch (err) {
        // console.log(err)
      }
    };

    DataCount();
  }, []);

  const [singlebid, setSingleBid] = useState(null);
  // console.log(singlebid);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  // console.log(id)

  useEffect(() => {
    const SingleBid = async () => {
      try {
        setLoading(true);

        const response = await Requests.get(`mybids/${id}`);
        // console.log(response.data);
        setSingleBid(response.data);
        setLoading(false);
      } catch (err) {
        // console.log(err);
        if (err.response.status === 401) {
          toast.error("Not Authorized");
          navigate("/sign-in");
        } else if (err.response.status === 500) {
          toast.error("A problem with our servers, hang on");
        }
      } finally {
        setLoading(false);
      }
    };

    SingleBid();
  }, [id, navigate]);

  const renderFile = (fileUrl, fileName) => {
    return (
      // className="file-item"
      <div key={fileUrl} style={{ marginTop: "20px" }}>
        <a
          href={fileUrl}
          download={fileName}
          target="_blank"
          rel="noopener noreferrer"
        >
          {fileName}
        </a>
      </div>
    );
  };
  const formatDeadline = (deadline) => {
    const now = moment();
    const end = moment(deadline);
    const duration = moment.duration(end.diff(now));
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.minutes());
    return `${hours} hours ${minutes} minutes`;
  };

  //DELETING A BID

  const Deletebid = async () => {
    try {
      setLoading(true);
      const deleteData = {
        orderid: id,
      };
      // console.log(deleteData)

      // const response = await Requests.delete('deletebid', deleteData)
      const response = await axios.delete(
        "https://freelancer-server-flk4.onrender.com/api/freelancers/deletebid",
        {
          data: deleteData,
          withCredentials: true,
        }
      );

      // console.log(response.data)
      toast.success("Bid Deleted Successfully");

      setTimeout(() => {
        navigate("/bids");
      }, 1000);
      setLoading(false);
    } catch (err) {
      // console.log(err)
      if (err.response.status === 401) {
        toast.error("Not Authorized");
        navigate("/sign-in");
      } else if (err.response.status === 500) {
        toast.error("A problem with our servers, hang on");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} />

      <main className="main-dashboard">
        <div className={`sidebar-render ${isSidebarOpen ? "open" : ""}`}>
          <Sidebar isOpen={isSidebarOpen} sidebarData={countData} />
        </div>

        <div className="dashboard-render">
          <div className="dashboard-render-container available-jobs">
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "21px",
                marginTop: "20px",
              }}
            >
              My Bids Gig Details
            </p>

            <div className="gig-details">
              <div className="bids">
                <div className="job-name">
                  <p>{singlebid?.title || "Title"}</p>
                </div>

                <div className="job-description">
                  <p>{singlebid?.description || "Description"}</p>
                </div>

                <div className="files">
                  <p>Attached Files</p>
                  {singlebid?.files && singlebid.files.length > 0 ? (
                    singlebid.files.map((fileUrl, index) =>
                      renderFile(fileUrl, `File ${index + 1}`)
                    )
                  ) : (
                    <p>No attached files</p>
                  )}
                </div>

                <div className="place-bid">
                  <p style={{ fontWeight: "600" }}>
                    {" "}
                    Bids: {singlebid?.totalbids || "0"}
                  </p>

                  <p
                    style={{
                      fontSize: "18px",
                      marginTop: "20px",
                      fontWeight: "500",
                      marginLeft: "5px",
                    }}
                  >
                    Your bid
                  </p>

                  <div className="bid-statement">
                    <div className="bid-statement-actual-bid">
                      <p>
                        {singlebid?.bids?.length > 0
                          ? singlebid.bids[0].bidtext
                          : "No bid text available."}
                      </p>
                    </div>

                    <div className="bid-button">
                      <button onClick={Deletebid}>
                        {loading ? (
                          <AiOutlineLoading3Quarters className="loading-icon" />
                        ) : (
                          "Delete Your Bid?"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="details">
                <div className="specifics">
                  <p style={{ color: "white", fontWeight: "bold" }}>
                    Gig Specifications
                  </p>

                  <div className="specifics-container">
                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Gig Name</p>
                      </div>
                      <div className="gig-specs-value overflow-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          {singlebid?.title || "Title"}
                        </p>
                      </div>
                    </div>

                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Deadline</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "red", fontWeight: "bold" }}>
                          {singlebid
                            ? formatDeadline(singlebid.deadline)
                            : "No deadline"}
                        </p>
                      </div>
                    </div>

                    {/* <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Payment Date</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "green", fontWeight: "bold" }}>
                          {singlebid?.payment_date || Date.now()}
                        </p>
                      </div>
                    </div> */}

                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>category</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          {singlebid?.category || "Writing"}
                        </p>
                      </div>
                    </div>
                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Subcategory</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          {singlebid?.subcategories || "Writing"}
                        </p>
                      </div>
                    </div>

                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Budget</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          {/* writerbudget */}${" "}
                          {singlebid?.budwriterbudgetget || "0"}
                        </p>
                      </div>
                    </div>
                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Paper Format</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          {singlebid?.paperformat || "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Bidjobdescription;
