import React, { useState } from "react";
import "./upload.css";
import Requests from "../../../../API/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from "axios";
import { useNavigate } from "react-router-dom";
function FileUpload(orderId) {
  const [files, setFiles] = useState([]);
  const [uploadedUrls, setUploadedUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  let OrderID = orderId.orderId;

  // Function to handle new files added to the upload list
  const handleFiles = (newFiles) => {
    setFiles([...files, ...newFiles]);
  };

  // Function to handle drag over event
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // Function to handle drop event
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFiles = Array.from(e.dataTransfer.files);
    handleFiles(droppedFiles);
  };

  // Function to handle file selection via file input
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    handleFiles(selectedFiles);
  };

  // Function to remove a file from the upload list
  const removeFile = (indexToRemove) => {
    setFiles(files.filter((_, index) => index !== indexToRemove));
  };

  const navigate = useNavigate();
  // Function to handle form submission
  const handleSubmit = async () => {
    if (files.length > 0) {
      setLoading(true);
      try {
        const uploadFilesToCloudinary = async () => {
          // const cloudinaryUrl =
          //   "https://api.cloudinary.com/v1_1/dgofftfvk/upload";
          // const uploadPreset = "muohmkoe";

          const cloudinaryUrl =
            "https://api.cloudinary.com/v1_1/drh36px3j/upload";
          const uploadPreset = "upload";

          const uploadedFiles = await Promise.all(
            files.map(async (file) => {
              const formData = new FormData();
              formData.append("file", file);
              formData.append("upload_preset", uploadPreset);

              const res = await axios.post(cloudinaryUrl, formData, {
                headers: { "Content-Type": "multipart/form-data" },
              });
              // console.log(res);

              return {
                secure_url: res.data.secure_url,
              };
            })
          );

          setUploadedUrls(uploadedFiles);
          return uploadedFiles;
        };

        const urls = await uploadFilesToCloudinary();
        const secureUrls = urls.map((url) => url.secure_url); // Extracting secure_url values
        const data = {
          orderid: OrderID,
          submittedfiles: secureUrls,
        };

        if (!data) {
          return toast.error("Choose Files To Submit");
        }

        const response = await Requests.post("submittask", data);
        // console.log("Response from backend:", response);
        toast.success("Files submitted successfully.");

        setTimeout(() => {
          navigate("/progress");
        }, 1000);
      } catch (err) {
        // console.error("Error submitting files to backend:", err);
        if (err.response.status === 401) {
          toast.error("Not Authorized");
          navigate("/sign-in");
        } else if (err.response.status === 500) {
          toast.error("A problem with our servers, hang on");
        }

        toast.error("Error submitting files.");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    } else {
      // console.log("No files to upload.");
      toast.warn("No files to upload.");
    }
  };

  return (
    <div className="file-upload-container">
      <div
        className="file-upload-area"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <p>
          Drag and drop files here or{" "}
          <span className="file-upload-choose">choose</span>
        </p>
        <input
          type="file"
          multiple
          className="file-input"
          onChange={handleFileChange}
        />
      </div>
      <button className="upload-button" onClick={handleSubmit}>
        {loading ? (
          <AiOutlineLoading3Quarters className="loading-icon" />
        ) : (
          "Submit"
        )}
      </button>
      <div className="file-list">
        {files.map((file, index) => (
          <div key={index} className="file-item">
            <p>{file.name}</p>
            <button className="remove-button" onClick={() => removeFile(index)}>
              Remove
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FileUpload;
