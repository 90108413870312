import React, { useState, useEffect } from "react";
import Navbar from "../../Dashboard/navbar/nav";
import Sidebar from "../../Dashboard/sidebar/sidebar";
import { useNavigate, useParams } from "react-router-dom";
import Requests from "../../../API/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
function DisputedDescription() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [countData, setcountData] = useState({});

  useEffect(() => {
    const DataCount = async () => {
      try {
        const response = await Requests.get("getAllOrderCounts");
        // console.log(response.data)
        setcountData(response.data);
      } catch (err) {
        // console.log(err)
      }
    };

    DataCount();
  }, []);

  const [singleDispute, setsingleDispute] = useState(null);
  // console.log(singleDispute);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  // console.log(id)

  useEffect(() => {
    const SingleorderDispute = async () => {
      try {
        setLoading(true);

        const response = await Requests.get(`singleorder/${id}`);
        // console.log(response.data.singleorderdetails)
        setsingleDispute(response.data.singleorderdetails);
        setLoading(false);
      } catch (err) {
        // console.log(err);
        if (err.response.status === 401) {
          toast.error("Not Authorized");
          navigate("/sign-in");
        } else if (err.response.status === 500) {
          toast.error("A problem with our servers, hang on");
        }
      } finally {
        setLoading(false);
      }
    };

    SingleorderDispute();
  }, [id, navigate]);

  const renderFile = (fileUrl, fileName) => {
    return (
      // className="file-item"
      <div key={fileUrl} style={{ marginTop: "20px" }}>
        <a
          href={fileUrl}
          download={fileName}
          target="_blank"
          rel="noopener noreferrer"
        >
          {fileName}
        </a>
      </div>
    );
  };

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} />

      <main className="main-dashboard">
        <div className={`sidebar-render ${isSidebarOpen ? "open" : ""}`}>
          <Sidebar isOpen={isSidebarOpen} sidebarData={countData} />
        </div>

        <div className="dashboard-render">
          <div className="dashboard-render-container available-jobs">
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "21px",
                marginTop: "20px",
              }}
            >
              Disputed Gig Details
            </p>

            <div className="gig-details">
              <div className="bids">
                <div className="job-name">
                  <p>{singleDispute?.order?.title || "Title"}</p>
                </div>

                <div className="job-description">
                  <p>{singleDispute?.order?.description || "description"}</p>
                </div>

                <div className="files">
                  {/* <p>Attached Files</p> */}

                  <p>Your Submitted Files</p>

                  {singleDispute?.order.submittedfiles &&
                  singleDispute.order.submittedfiles.length > 0 ? (
                    singleDispute.order.submittedfiles.map((fileUrl, index) =>
                      renderFile(fileUrl, `File ${index + 1}`)
                    )
                  ) : (
                    <p>No attached files</p>
                  )}

                  <p style={{ marginTop: "10px" }}>Dispute Comments</p>

                  <p style={{ color: "red", marginTop: "2px" }}>
                    {singleDispute?.order?.reasondispute || "dispute reason"}
                  </p>
                </div>
              </div>

              <div className="details">
                <div className="specifics">
                  <p style={{ color: "white", fontWeight: "bold" }}>
                    Gig Specifications
                  </p>

                  <div className="specifics-container">
                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Gig Name</p>
                      </div>
                      <div className="gig-specs-value overflow-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          {singleDispute?.order?.title || "Title"}
                        </p>
                      </div>
                    </div>
                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Category</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          {singleDispute?.order?.category || "Title"}
                        </p>
                      </div>
                    </div>
                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>SubCategory</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          {singleDispute?.order?.subcategories || "Title"}
                        </p>
                      </div>
                    </div>

                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Budget</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "white", fontWeight: "bold" }}>
                          $ {singleDispute?.order?.writerbudget || "0"}
                        </p>
                      </div>
                    </div>

                    <div className="gig-specs">
                      <div className="gig-specs-name">
                        <p>Status</p>
                      </div>
                      <div className="gig-specs-value">
                        <p style={{ color: "red", fontWeight: "bold" }}>
                          {singleDispute?.order?.status || "Disputed"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default DisputedDescription;
